import React from 'react';
import './Video.css';
import content from '../../content/video.json';

export const Video = () => {
	return <div className="container video">
		<h2>{content.title}</h2>
		<>
			{content.paragraphs && content.paragraphs.length ? content.paragraphs.map((p, i) => (<p key={`video-p${i}`}>{p}</p>)) : null}
			{content.content ? <div className="content" dangerouslySetInnerHTML={{
				__html: content.content
			}}></div>: ''}
		</>
	</div>
};
