import React from 'react';
import './Header.css';
import banner from '../../img/banner2.jpg';

export const Header = () => {
	return <header className="header">
		<img
			src={banner}
			title="banner"
			className="banner"
		/>
		<div className="container">
			<h1>Nasze piosenki</h1>
		</div>
	</header>
};
