import React from 'react';
import './Songs.css';
import content from '../../content/songs.json';
import {Expandable} from '../expandable/Expandable';
import songList from '../../content/songList.json';

export const Songs = () => {

	let list = [];

	for (let i = 0; i < songList.length; i++) {
		
		if (!list.includes(songList[i].author)) {
			list.push(songList[i].author);
		}

	}

	list = list.map(author => ({
		author: author,
		items: songList.filter(item => item.author === author),
	}));

	return <div className="container songs">
		<h2>{content.title}</h2>
		<div>
			{content.paragraphs && content.paragraphs.length ? content.paragraphs.map((p, i) => (<p key={`songs-p${i}`}>{p}</p>)) : null}
			{content.content ? <div className="content" dangerouslySetInnerHTML={{
				__html: content.content
			}}></div>: ''}
		</div>
		{list.map((group, i) => <div
			key={`exp-${i}`}
		>
			<h3>{group.author}</h3>
			<Expandable
				items={group.items}
			/>
		</div>)}
	</div>
};
