import React from 'react';
import {useState} from 'react';
import './Gallery.css';
import content from '../../content/gallery.json';
import imageList from '../../content/imageList.json';

export const Gallery = () => {

	let [hidden, setHidden] = useState(true);
	let [imageIndex, setImageIndex] = useState(0);
	let [prevDisabled, setPrevDisabled] = useState(false);
	let [nextDisabled, setNextDisabled] = useState(false);
	const showOverlay = () => setHidden(false);
	const hideOverlay = () => setHidden(true);
	let [currentImg, setCurrentImg] = useState({
		src: '',
		title: '',
	});
	const imageClickHandler = (img, show, index) => {

		if (show) {
			showOverlay();
		} else {
			hideOverlay();
		}

		setCurrentImg(img);
		setImageIndex(index);

		if (index === 0) {
			setPrevDisabled(true);
		}

		if (index === imageList.length - 1) {
			setNextDisabled(true);
		}

	};
	const galleryNavHandler = (increment) => {
		
		let index = imageIndex + increment;

		if (index >= 0 && index < imageList.length) {
			setCurrentImg(imageList[index]);
			setImageIndex(index);
			setPrevDisabled(false);
			setNextDisabled(false);

			if (index === 0) {
				setPrevDisabled(true);
				setNextDisabled(false);
			}

			if (index === imageList.length - 1) {
				setNextDisabled(true);
				setPrevDisabled(false);
			}

		}

	};

	return <div className="container gallery">
		<h2>{content.title}</h2>
		<div>
			{content.paragraphs && content.paragraphs.length ? content.paragraphs.map((p, i) => (<p key={`gallery-p${i}`}>{p}</p>)) : null}
		</div>
		<div 
			className="gallery-content"
		>
			{imageList.map((img, i) => <div
				key={`img-${i}`}
				className="thumbnail"
				onClick={() => imageClickHandler(img, true, i)}
			>
				<p className="image-title">{img.author}</p>
				<img
					src={img.src}
					title={img.title}
				/>
			</div>)}
		</div>
		<div
			className={hidden ? "overlay" : "overlay show"}
			onClick={() => imageClickHandler({title: '', src: ''}, false, 0)}
		>
		</div>
		<div
			className={hidden ? "gallery-nav prev" : "gallery-nav prev show" + (prevDisabled ? ' disabled' : '')}
			onClick={() => galleryNavHandler(-1)}
		></div>
		<img
			src={currentImg.src}
			title={currentImg.title}
			className={hidden ? "current-img" : "current-img show"}
		/>
		<div
			className={hidden ? "gallery-nav next" : "gallery-nav next show" + (nextDisabled ? ' disabled' : '')}
			onClick={() => galleryNavHandler(1)}
		></div>
		<div
			className={hidden ? "gallery-nav close" : "gallery-nav close show"}
			onClick={() => imageClickHandler({title: '', src: ''}, false, 0)}
		>
		</div>
	</div>
};
