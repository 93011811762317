import React from 'react';
import './Contact.css';
import content from '../../content/contact.json';

export const Contact = () => {
	return <div className="container contact">
		<h2>{content.title}</h2>
		<>
			{content.paragraphs && content.paragraphs.length ? content.paragraphs.map((p, i) => (<p key={`contact-p${i}`}>{p}</p>)) : null}
			{content.content ? <div className="content" dangerouslySetInnerHTML={{
				__html: content.content
			}}></div>: ''}
		</>
	</div>
};
