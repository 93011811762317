import React from 'react';
import './Footer.css';
import {Navigation} from '../navigation/Navigation';

export const Footer = () => {
	return <footer className="footer">
		<div className="container">
			<a
				href="surya-sound.com"
			>
				surya-sound.com
			</a>
		</div>
	</footer>
};
