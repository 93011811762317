import React from 'react';
import {useState} from 'react';
import './Expandable.css';

export const Expandable = (props) => {

	const itemList = props.items.map(item => ({
		title: item.title,
		content: Array.isArray(item.content) ? item.content : {__html: item.content},
		active: false,
	}));
	const [items, setItems] = useState(itemList);
	const clickHandler = index => {
		setItems(items.map((item, i) => ({
			title: item.title,
			content: item.content,
			active: i === index ? !item.active : item.active,
		})));
	};

	return <div className="expandable">
		{items.map((item, i) => <div
			key={`item-${i}`}
			className="expandable-item"
		>
			<h4 onClick={() => clickHandler(i)}>{item.title}</h4>
			<div
				className={item.active ? "expandable-content active" : "expandable-content"}
			>
				{Array.isArray(item.content) ?  item.content.map((paragraph, j) => <p
					key={`paragraph-${i}${j}`}
				>
					{paragraph}
				</p>) : <div dangerouslySetInnerHTML={item.content}></div>}
			</div>
		</div>)}
	</div>
};
