import React from 'react';
import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import {Navigation} from './navigation/Navigation';
import {Header} from './header/Header';
import {Footer} from './footer/Footer';
import {Home} from './home/Home';
import {Songs} from './songs/Songs';
import {Contact} from './contact/Contact';
import {Video} from './video/Video';
import {Gallery} from './gallery/Gallery';
import './App.css';

export const App = () => (
	<div className="app">
		<Router>
			<Header />
			<main className="main">
				<div className="container">
					<Navigation />
				</div>
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/songs" element={<Songs />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/video" element={<Video />} />
					<Route path="/gallery" element={<Gallery />} />
				</Routes>
			</main>
			<Footer />
		</Router>
	</div>
);
