import React from 'react';
import './Home.css';
import content from '../../content/home.json';

export const Home = () => {
	return <div className="container home">
		<h2>{content.title}</h2>
		<>
			{content.paragraphs && content.paragraphs.length ? content.paragraphs.map((p, i) => (<p key={`home-p${i}`}>{p}</p>)) : null}
			{content.content ? <div className="content" dangerouslySetInnerHTML={{
				__html: content.content
			}}></div>: ''}
		</>
	</div>
};
