import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import './Navigation.css';

export const Navigation = () => {

	const [nav, setNav] = useState([{
		label: 'Główna',
		active: window.location.hash === '#/',
		href: '/',
	}, {
		label: 'Piosenki',
		active: window.location.hash === '#/songs',
		href: '/songs',
	}, {
		label: 'Kontakt',
		active: window.location.hash === '#/contact',
		href: '/contact',
	}, {
		label: 'Video',
		active: window.location.hash === '#/video',
		href: '/video',
	}, {
		label: 'Galeria',
		active: window.location.hash === '#/gallery',
		href: '/gallery',
	}]);
	const navigate = useNavigate();
	const navigateOnClick = (e, index) => {
		setNav(nav.map((n, i) => i === index ? ({
			label: n.label,
			active: true,
			href: n.href,
		}) : ({
			label: n.label,
			active: false,
			href: n.href,
		})));
		navigate(e.target.getAttribute('data-href'))
	};

	return <nav className="navigation">
		<ul>
			{nav.map((n, i) => (<li
				key={`nav-el-${i}`}
			>
				<button
					onClick={(e) => navigateOnClick(e, i)}
					data-href={n.href}
					className={n.active ? 'active' : ''}
				>
					{n.label}
				</button>
			</li>))}
		</ul>
	</nav>
};
